import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page403 = React.lazy(() => import("./views/Pages/Page403"));
const Page501 = React.lazy(() => import("./views/Pages/Page501"));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      isUsingDefaultPassword: false
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (error) {
      if (error !== "No current user") {
        console.log(error);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  userShouldChangePassword = hasDefaultPassword => {
    this.setState({ isUsingDefaultPassword: hasDefaultPassword });
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      isUsingDefaultPassword: this.state.isUsingDefaultPassword,
      userShouldChangePassword: this.userShouldChangePassword,
      logOut: async () => {
        window.location = "/";
        await Auth.signOut();
        this.userHasAuthenticated(false);
      }
    };

    return (
      !this.state.isAuthenticating && (
        <HashRouter>
          <React.Suspense fallback={loading()}>
            {this.state.isAuthenticated ? (
              <Switch>
                <Route
                  exact
                  path="/501"
                  name="501"
                  render={props => <Page501 {...props} {...childProps} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={props => <DefaultLayout {...props} {...childProps} />}
                />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={props => <Login {...props} {...childProps} />}
                />
                <Route
                  exact
                  path="/501"
                  name="501"
                  render={props => <Page501 {...props} {...childProps} />}
                />
                <Route
                  path="*"
                  name="403"
                  render={props => <Page403 {...props} {...childProps} />}
                />
              </Switch>
            )}
          </React.Suspense>
        </HashRouter>
      )
    );
  }
}

export default App;
